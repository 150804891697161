import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getJwtToken } from 'util/Storage.util';
import { isJwtExpired } from 'jwt-check-expiration';
import { toast } from 'react-toastify';

// const TIMEOUT = 1 * 60 * 1000;
const TIMEOUT = 2 * 600 * 1000;
axios.defaults.timeout = TIMEOUT;

const setupAxiosInterceptors = (onUnauthenticated) => {
  const onRequestSuccess = (config: AxiosRequestConfig) => {
    const token = getJwtToken();
    // const token = 'getJwtToken()';
    if (token) {
      // @ts-ignore
      config.headers.Authorization = `Bearer ${token}`;
    }
    // @ts-ignore
    config.headers.Prefer = 'count=exact, return=representation';
    return config;
  };

  const onResponseSuccess = (response) => response;

  const onRequestError = (error) => Promise.reject(error);

  const onResponseError = (err: AxiosError) => {
    const status = err.response ? err.response.status : 0;
    if (status === 403 || status === 401) {
      const token = getJwtToken();
      if (!token) {
        toast.warning('Время жизни сессии истекло! Выполните вход повторно!');
        onUnauthenticated();
      }
      if (isJwtExpired(token)) {
        toast.warning('Время жизни сессии истекло! Выполните вход повторно!');
        onUnauthenticated();
      }
    }

    return Promise.reject(err);
  };

  axios.interceptors.request.use(onRequestSuccess, onRequestError);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;

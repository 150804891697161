/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'config/Store';
import { toast } from 'react-toastify';
import { setArm } from 'reducers/Authentication.reducer';
import { serializeAxiosError } from 'util/Reducer.utils';
import { ArmsArray } from 'pages/Arm/arms';
import { showAndOpenSideBar } from './Sidebar.reducer';

interface IConfig {
  id?: number;
  code: string;
  name?: string;
  descr?: string;
  num?: number;
  icon: string;
  visible: boolean;
  enabled: boolean;
}

const initialState = {
  loading: false,
  errorMessage: null as unknown as string,
  successMessage: null as unknown as string,
  config: [] as IConfig[],
};

export type SettingsState = Readonly<typeof initialState>;

export const getConfig = createAsyncThunk(
  'config/get_config',
  () =>
    // async () =>
    // axios.get<any>(apiUrl)
    ArmsArray,
  {
    serializeError: serializeAxiosError,
  }
);

export const ConfigSlice = createSlice({
  name: 'config',
  initialState: initialState as SettingsState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(getConfig.rejected, (state) => {
        state.loading = false;
        state.errorMessage = 'Невозможно получить меню';
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMessage = 'Получили меню';
        // state.config = action.payload.data;
        state.config = action.payload;
      });
  },
});

export const checkArmFromUrl: (path: any) => AppThunk =
  (path) =>
  // async
  (dispatch) => {
    try {
      const urlArm = String(path?.pathname)?.split('/')?.[1]?.toUpperCase();
      if (urlArm.length > 0) {
        // const url = process.env.REACT_APP_FRONTEND_URL;
        // const response = await axios.get(`${url}/api/cfg_config?code=eq.${urlArm}`);

        // было так, потом запрашивать через Gateway
        // const response = await appAxios.get(`cfg_config?code=eq.${urlArm}`);
        const response = ArmsArray.filter((item) => item.code === urlArm);
        if (response?.length === 1) {
          dispatch(setArm(urlArm));
          dispatch(showAndOpenSideBar());
          dispatch(getConfig());
        }
      }
    } catch (error) {
      toast.error(`Ошибка при проверке АРМа из строки запроса! ${error}`);
    }
  };

export const { reset } = ConfigSlice.actions;

// Reducer
export default ConfigSlice.reducer;

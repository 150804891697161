import React from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact';
import { useHistory } from 'react-router-dom';
import styles from 'shared/error/PageWithError.module.css';
import { useTranslation } from 'react-i18next';

export interface IPageWithErrorProps {
  errorNumber: string;
  errorMessage: string;
}

const PageWithError: React.FC<IPageWithErrorProps> = ({ errorNumber, errorMessage }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol md="2" />
        <MDBCol md="8" style={{ textAlign: 'center' }}>
          <h1 className={styles.h1}>{errorNumber}</h1>
          <h3 className={styles.h3}>{errorMessage}</h3>
          <div>
            <MDBBtn className="danger-color-dark btn primary white-text" onClick={() => history.push('/')}>
              <p className="d-none d-xl-inline">{t('button')}</p> <MDBIcon far size="1x" icon="arrow-alt-circle-right" />
            </MDBBtn>
          </div>
        </MDBCol>
        <MDBCol md="2" />
      </MDBRow>
    </MDBContainer>
  );
};

export default PageWithError;

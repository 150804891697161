import { toast } from 'react-toastify';
import { isFulfilledAction, isRejectedAction } from 'util/Reducer.utils';

const addErrorAlert = (message, key?, data?) => {
  toast.error(message);
};

export default () => (next) => (action) => {
  const { error, payload } = action;

  /**
   * Middleware служит для добавления уведомлений об успехах и ошибках
   */
  if (isFulfilledAction(action) && payload && payload.headers) {
    const headers = payload?.headers;
    let alert: string | null = null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    headers &&
      Object.entries<string>(headers).forEach(([k, v]) => {
        if (k.toLowerCase().endsWith('app-alert')) {
          alert = v;
        }
      });
    if (alert) {
      toast.success(alert);
    }
  }

  if (isRejectedAction(action) && error && error.isAxiosError) {
    // TODO: разобраться что за фигня с CORS
    if (error.config.url.includes('/api/account') && error.message.includes('Network Error')) {
      console.log('CORS Error: Trying to access url api/account with OPTIONS');
      return next(action);
    }
    // TODO: разобраться что за фигня с CORS 2.0
    if (error.config.url.includes('/api/menu') && error.message.includes('Network Error')) {
      console.log('CORS Error: Trying to access url api/menu with OPTIONS');
      return next(action);
    }

    if (error.response) {
      const { response } = error;
      const { data } = response;

      if (
        !(
          response.status === 401 &&
          (error.message === '' ||
            (data && data.path && data.path.includes('/api/account')) ||
            data.path.includes('/api/authenticate') ||
            data.path.includes('/api/menu'))
        )
      ) {
        let i;

        switch (response.status) {
          // connection refused, server not reachable
          case 0:
            addErrorAlert('Server not reachable', 'error.server.not.reachable');
            break;

          case 400: {
            let errorHeader: string | null = null;
            let entityKey: string | null = null;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            response?.headers &&
              Object.entries<string>(response.headers).forEach(([k, v]) => {
                if (k.toLowerCase().endsWith('app-error')) {
                  errorHeader = v;
                } else if (k.toLowerCase().endsWith('app-params')) {
                  entityKey = v;
                }
              });
            if (errorHeader) {
              const entityName = entityKey;
              addErrorAlert(errorHeader, errorHeader, { entityName });
            } else if (data?.fieldErrors) {
              const { fieldErrors } = data;
              // eslint-disable-next-line no-plusplus
              for (i = 0; i < fieldErrors.length; i++) {
                const fieldError = fieldErrors[i];
                if (['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.message)) {
                  fieldError.message = 'Size';
                }
                // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                const fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
                addErrorAlert(`Error on field "${fieldName}"`, `error.${fieldError.message}`, { fieldName });
              }
            } else if (typeof data === 'string' && data !== '') {
              addErrorAlert(data);
            } else {
              toast.error(data?.message || data?.error || data?.title || 'Unknown error!');
            }
            break;
          }
          case 404:
            addErrorAlert('Not found', 'error.url.not.found');
            break;
          case 500:
            break;

          default:
            if (typeof data === 'string' && data !== '') {
              addErrorAlert(data);
            } else {
              toast.error(data?.message || data?.error || data?.title || 'Unknown error!');
            }
        }
      }
    } else if (error.config && error.config.url === 'api/account' && error.config.method === 'get') {
      console.log('Authentication Error: Trying to access url api/account with GET.');
    } else if (error.config && error.config.url === 'api/menu' && error.config.method === 'get') {
      console.log('Authentication Error: Trying to access url api/menu with GET.');
    } else {
      console.error(error.message || 'Unknown error!');
    }
  } else if (error) {
    console.error(error.message || 'Unknown error!');
  }

  return next(action);
};

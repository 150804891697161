import React, { useEffect, useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'config/Store';
import { logout } from 'reducers/Authentication.reducer';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import { useHistory } from 'react-router';

export const Logout = () => {
  const logoutUrl = useAppSelector((state) => state.authentication.logoutUrl);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useLayoutEffect(() => {
    dispatch(logout());
    if (logoutUrl) {
      window.location.href = logoutUrl;
    }
  });

  useEffect(() => {
    setTimeout(() => {
      history.push('/');
    }, 500);
  }, []);

  return (
    <MDBContainer fluid className="mt-2">
      <MDBRow center>
        <MDBCol className="text-center mt-4">
          <h2>
            Вы вышли из АС Прогруз <br />
          </h2>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Logout;

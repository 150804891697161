import { createSlice } from '@reduxjs/toolkit';
import { IVMdlCargo10 } from 'models/IVMdlCargo10.model';

export const modelingSlice = createSlice({
  name: 'modeling',
  initialState: {
    selectedCargo: {} as IVMdlCargo10,
    selectedShippingType: {},
    selectedDirectionArr: {},
    selectedPeriod: {},
    selectedShipper: {},
  },
  reducers: {
    setSelectedCargoR(state, action) {
      state.selectedCargo = action.payload;
    },
    setSelectedShippingTypeR(state, action) {
      state.selectedShippingType = action.payload;
    },
    setSelectedDirectionArrR(state, action) {
      state.selectedDirectionArr = action.payload;
    },
    setSelectedPeriodR(state, action) {
      state.selectedPeriod = action.payload;
    },
    setSelectedShipperR(state, action) {
      state.selectedShipper = action.payload;
    },
  },
});

// Action Creators генерируются для каждого reducer и case
export const { setSelectedCargoR, setSelectedShippingTypeR, setSelectedDirectionArrR, setSelectedPeriodR, setSelectedShipperR } =
  modelingSlice.actions;

// Reducer
export default modelingSlice.reducer;

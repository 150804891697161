import React, { FC } from 'react';
import { MDBSpinner } from 'mdbreact';

interface IPreloaderProps {
  size?: string;
  color?: string;
  text?: string;
}

const Preloader: FC<IPreloaderProps> = ({ size, color, text }) => (
  <div className="align-middle mx-auto" style={{ margin: '0px', padding: '0px' }}>
    <span>{text}</span>
    <MDBSpinner
      // blue
      red={color == 'red'}
      green={color == 'green'}
      multicolor={color == 'multicolor'}
      yellow={color == 'yellow'}
      small={size === 'small'}
      // color={color}
    />
  </div>
);

export default Preloader;

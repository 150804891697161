import { MDBIcon } from 'mdbreact';
import React from 'react';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu } from 'react-pro-sidebar';
import 'common/SideBar/custom.scss';
import { NavLink, useLocation } from 'react-router-dom';
import 'common/SideBar/scrollbar.css';
import Logo from 'assets/pzhd_logo.png';
import { useAppSelector } from 'config/Store';
import { nanoid } from 'nanoid';
import styles from 'common/SideBar/SideBar.module.css';
import sideBarLinks from 'common/SideBar/sideBarLinks';

const SideBar = ({ info }) => {
  const sideBarIsOpen = useAppSelector((state) => state.sidebar.isOpen);
  const currentArm = useAppSelector((state) => state.authentication.currentArm);
  const menuItems: any = useAppSelector((state) => state.sidebar.menuItems);
  const isLoadingMenu: any = useAppSelector((state) => state.sidebar.isLoadingMenu);
  const sideBarIsCollapsed = useAppSelector((state) => state.sidebar.isCollapsed);
  const date = new Date();
  const currentYear = date.getFullYear();
  const location = useLocation();

  // const config = useAppSelector((state) => state.config.config);
  // const currentLocation = location?.pathname;
  // const armSplit = currentArm.split('_');

  const links = () =>
    // return menu
    sideBarLinks
      .filter((link) => link.arms.includes(currentArm) && link.enabled)
      .map((flink, index) => {
        if (flink.children.length > 0) {
          return (
            <SubMenu
              // @ts-ignore
              style={{ fontSize: '12px' }}
              key={flink.text + index}
              title={flink.text}
              icon={<MDBIcon icon={flink.icon} className="mr-1" />}
            >
              {flink.children
                .filter((link) => link.enabled)
                .map((child) => (
                  // @ts-ignore
                  <MenuItem style={{ fontSize: '12px' }} key={nanoid()} icon={<MDBIcon icon={child.icon} className="mr-1" />}>
                    <NavLink to={child.link}>
                      <div
                        style={{
                          // @ts-ignore
                          color: location.pathname === child.link && '#ffffff',
                        }}
                      >
                        {child.text}
                      </div>
                    </NavLink>
                  </MenuItem>
                ))}
            </SubMenu>
          );
        }
        if (flink.external) {
          return (
            <MenuItem
              // @ts-ignore
              style={{ fontSize: '12px', fontWeight: '400', margin: '0px' }}
              key={flink.link + index}
              icon={<MDBIcon icon={flink.icon} className="mr-1" />}
            >
              <a href={flink.link} target="_blank" rel="noreferrer">
                {flink.text}
              </a>
            </MenuItem>
          );
        }
        return (
          <MenuItem
            // @ts-ignore
            style={{ fontSize: '12px', fontWeight: '400', margin: '0px' }}
            key={flink.link + index}
            icon={<MDBIcon icon={flink.icon} className="mr-1" />}
          >
            <NavLink to={flink.link}>
              <div
                style={{
                  // @ts-ignore
                  color: location.pathname == flink.link && '#ffffff',
                }}
              >
                {flink.text}
              </div>
            </NavLink>
          </MenuItem>
        );
      });
  return sideBarIsCollapsed ? (
    <div style={{ height: '100vh', minHeight: '400px' }}>
      <ProSidebar
        collapsed={!sideBarIsOpen}
        // @ts-ignore
        style={{ border: '1px solid #cecece' }}
        className="background-main"
      >
        <SidebarHeader>
          <div
            style={{
              padding: '10px',
              fontWeight: 'bolder',
              fontSize: '10px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}
          >
            <NavLink to="/">
              <img src={Logo} alt="ОАО РЖД" style={{ width: '80px', marginBottom: '0px' }} />
            </NavLink>
          </div>
        </SidebarHeader>

        <SidebarContent
          className="scrollbar scrollbar-primary"
          // @ts-ignore
          style={{
            fontWeight: 'bolder',
            fontSize: '12px',
            margin: '2px',
            overflowY: 'auto',
          }}
        >
          {/* <div style={{ color: '#fff', margin: '15px 0px 5px 20px', textTransform: 'uppercase' }}>
            {currentLocation !== '/' &&
              armSplit?.length > 0 &&
              config?.length > 0 &&
              `АРМ «${config.filter((item) => item.code === armSplit[1])?.[0]?.name}»`}
          </div> */}
          {/* <Menu iconShape="circle">{menuItems?.length > 0 ? links() : isLoadingMenu ? <Preloader /> : null}</Menu> */}
          <Menu iconShape="circle">{links()}</Menu>
        </SidebarContent>
        {location.pathname.includes('scenarios') && (
          <div className="menu-versions">
            <div className="menu-versions__item">
              <div className="menu-versions__title">Frontend v{info.frontendTag}</div>{' '}
              <div className="menu-versions__date"> от {info.frontendDate}</div>
            </div>
            <div className="menu-versions__item">
              <div className="menu-versions__title">Backend v{info.backendTag}</div>{' '}
              <div className="menu-versions__date"> от {info.backendDate}</div>
            </div>
          </div>
        )}
        <SidebarFooter
          // @ts-ignore
          style={{ textAlign: 'center' }}
        >
          <div className={`sidebar-btn-wrapper ${styles.buttonWrapper}`}>{currentYear}. АС ПРОГРУЗ</div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  ) : (
    <></>
  );
};

export default SideBar;

import sidebar from 'reducers/Sidebar.reducer';
import authentication from 'reducers/Authentication.reducer';
import settings from 'reducers/Settings.reducer';
import passwordReset from 'reducers/PasswordReset.reducer';
import config from 'reducers/Config.reducer';
import userManagement from 'reducers/UserManagement.reducer';
import authorities from 'reducers/Authorities.reducer';
import contextModel from 'reducers/ContextModel.reducer';
import modeling from 'reducers/Modeling.reducer';
import step from 'reducers/StepValues.reducer';
import alerts from 'reducers/Alerts.reducer';

const rootReducer = {
  authentication,
  sidebar,
  settings,
  passwordReset,
  config,
  modeling,
  userManagement,
  authorities,
  contextModel,
  step,
  alerts,
};

export default rootReducer;

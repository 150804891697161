/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBIcon } from 'mdbreact';
import card from 'common/RolesMenu/card.module.css';

interface ICardItem {
  name?: string;
  icon: string;
  description?: string;
  // roleCode: string;
  visible: boolean;
  enabled: boolean;
  isOldProgruz: boolean;
  url: string;
  onClick: () => void;
}

const CardItem: FC<ICardItem> = ({ name, icon, description, visible, enabled, onClick, isOldProgruz, url }) => (
  <>
    {visible ? (
      enabled ? (
        <>
          <MDBCol size="4" className="mb-4" style={{ minWidth: card.minWidth, width: '300px' }}>
            <MDBCard
              className="h-100 w-100"
              onClick={() => {
                onClick();
              }}
            >
              <MDBCardBody className={card.menu}>
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <MDBIcon className={`mr-3 ${card.icon}`} icon={icon} />
                    <MDBCardTitle className={`m-0 ${card.title}`}>{name}</MDBCardTitle>
                  </div>
                  <div className={`${card.border}`} />
                  <div>
                    <MDBCardText>
                      <span className={card.description}>{description}</span>
                    </MDBCardText>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </>
      ) : (
        <MDBCol size="4" className={`mb-3  ${card.minWidth}`}>
          <MDBCard className="h-100 w-100" style={{ boxShadow: '0 2px 5px 0 rgb(0 0 0 / 12%), 0 2px 10px 0 rgb(0 0 0 / 8%)' }}>
            <MDBCardBody className={card.disabledmenu}>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <MDBIcon className={`mr-3 ${card.disabledicon}`} icon={icon} />
                  <MDBCardTitle className={`m-0 ${card.disabledtitle}`} tag="h6">
                    {name}
                  </MDBCardTitle>
                </div>
                <div className={`${card.disabledborder}`} />
                <div>
                  <MDBCardText style={{ color: '#cecece' }}>{description}</MDBCardText>
                </div>
              </div>
              <div style={{ textAlign: 'center', position: 'absolute', left: '50%', bottom: '15px', marginLeft: '-5px' }}>
                <MDBIcon icon="eye-slash" style={{ color: '#cecece' }} />
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      )
    ) : null}
  </>
);

export default CardItem;

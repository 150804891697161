import React, { useState } from 'react';
import Logo from 'assets/RZD_logo_st_neg_rgb_2.svg';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useAppSelector } from 'config/Store';
import { Checkbox } from '../common/Checkbox';
import { Input } from '../common/Input';
import { ReactComponent as UserIcon } from '../../assets/images/user.svg';
import { ReactComponent as KeyIcon } from '../../assets/images/key.svg';
import { ReactComponent as PasswordStrokeEye } from '../../assets/images/password-stroke-eye.svg';
import { ReactComponent as ExclamationCircle } from '../../assets/images/exclamation_circle.svg';
import { Loader } from '../common/Loader';

interface AuthFormValues {
  username: string;
  password: string;
  changePassword: boolean;
}

const defaultValues: AuthFormValues = {
  username: '',
  password: '',
  changePassword: false,
};

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[()'"|;:+~`#?!@$%^&*=-]).{12,}$/;

export interface AuthFormProps {
  handleLogin: (username: string, password: string, changePassword: boolean) => void;
  loginError: boolean;
}

export const AuthForm = ({ handleLogin, loginError }: AuthFormProps) => {
  const loading = useAppSelector((state) => state.authentication.loading);
  const errorMessage = useAppSelector((state) => state.authentication.errorMessage);
  const form = useForm({ defaultValues, mode: 'onChange' });

  const [passwordInputType, setPasswordInputType] = useState('password');

  const handleShowPassword = () => {
    setPasswordInputType((prevState) => (prevState === 'password' ? 'text' : 'password'));
  };

  const validatePassword = (data: AuthFormValues['password']) => true || passwordRegex.test(data);

  const onSubmit = (data: AuthFormValues) => {
    const { username, password, changePassword } = data;
    handleLogin(username, password, changePassword);
  };

  return (
    <form className="auth-form" onSubmit={form.handleSubmit(onSubmit)}>
      <div className="auth-form__header">
        <img src={Logo} alt="ОАО РЖД" style={{ width: '80px', marginBottom: '0px' }} />
        <div className="auth-form__title">АС ПРОГРУЗ</div>
      </div>
      <div className="auth-form__description">
        <span className="auth-form__description__title">Авторизация</span>
        {loginError ? (
          <>
            <span className="auth-form__description__error">
              <strong>Ошибка!</strong>
            </span>
            <span className="auth-form__description__error">{errorMessage}</span>
            <span className="auth-form__description__error">Проверьте введенные данные и попробуйте ещё раз</span>
          </>
        ) : null}
      </div>
      <div className="auth-form__body">
        <div className="auth-form__fields">
          <Controller
            control={form.control}
            name="username"
            render={(field) => (
              <Input
                label={
                  <span className="auth-form__field__label">
                    Имя пользователя <span className="required">*</span>
                  </span>
                }
                trailingIcon={<UserIcon className="auth-form__field__icon" />}
                placeholder="Введите логин"
                inputClassName="auth-form__field"
                required
                {...field}
              />
            )}
          />
          <Controller
            control={form.control}
            name="password"
            rules={{ validate: validatePassword }}
            render={(field, state) => (
              <Input
                label={
                  <span className={classNames('auth-form__field__label', { error: state.invalid })}>
                    Пароль <span className="required">*</span>
                  </span>
                }
                trailingIcon={
                  <div onClick={handleShowPassword}>
                    {passwordInputType === 'password' ? (
                      <KeyIcon className="auth-form__field__icon show-password" />
                    ) : (
                      <PasswordStrokeEye className="auth-form__field__icon show-password" />
                    )}
                  </div>
                }
                additionalText={
                  <div className={classNames('auth-form__field__additionalText', { error: state.invalid, hidden: !state.invalid })}>
                    <ExclamationCircle />
                    <span>
                      Пароль должен быть не короче 12 символов, без пробелов, содержать символы из четырех групп: буквы A-Z, a-z, цифры 0-9,
                      спецсимволы #!@$%^&*
                    </span>
                  </div>
                }
                required
                inputClassName="auth-form__field"
                placeholder="Введите пароль"
                type={passwordInputType}
                isError={state.invalid}
                {...field}
              />
            )}
          />
        </div>
        <div className="auth-form__actions">
          <button type="submit" className="auth-form__submit button" disabled={!form.formState.isValid || loading}>
            {!loading ? 'Войти' : <Loader />}
          </button>
          <div className="auth-form__additional-actions">
            <Controller
              control={form.control}
              name="changePassword"
              render={(field) => (
                <Checkbox
                  label="Изменить пароль"
                  isCheckedProp={field.value}
                  onChange={(value) => form.setValue('changePassword', value)}
                />
              )}
            />
            <a className="forgot-password" href="http://10.248.62.211:8080/GeneralForm/index.html">
              Забыли пароль?
            </a>
          </div>
        </div>
      </div>
    </form>
  );
};

import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { defaultValue } from 'models/IUser.model';
import { IQueryParams } from 'util/Reducer.utils';

interface IAuthority {
  name?: string;
  label?: string;
}

const initialState = {
  loading: false,
  errorMessage: null as unknown as string,
  authorities: [] as ReadonlyArray<IAuthority>,
  user: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
};

const apiUrl = `/v2/api/authorities`;

export const getAuthorities = createAsyncThunk('authorities/fetch_authorities', async ({ page, size }: IQueryParams) => {
  const requestUrl = `${apiUrl}${size ? `?page=${page}&pageSize=${size}` : ''}`;
  return axios.get<IAuthority[]>(requestUrl);
});

export type AuthoritiesState = Readonly<typeof initialState>;

export const AuthoritiesSlice = createSlice({
  name: 'authorities',
  initialState: initialState as AuthoritiesState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getAuthorities), (state, action) => {
        state.loading = false;
        state.authorities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isPending(getAuthorities), (state) => {
        // @ts-ignore
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isRejected(getAuthorities), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message ?? 'невозможно получить ошибку';
      });
  },
});

export const { reset } = AuthoritiesSlice.actions;

// Reducer
export default AuthoritiesSlice.reducer;

import { serializeAxiosError } from 'util/Reducer.utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appAxios from 'services/AppAxios';

export const initialState = {
  isOpen: false,
  isCollapsed: false,
  menuItems: null,
  isLoadingMenu: false,
};

export const getMenuItems = createAsyncThunk('SideBar/getMenuItems', async () => appAxios.get<any>(`v_sys_menu_item?order=id.asc`), {
  serializeError: serializeAxiosError,
});

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    openOrCloseSidebar(state) {
      state.isOpen = !state.isOpen;
    },
    showOrCollapseSidebar(state) {
      state.isCollapsed = !state.isCollapsed;
    },
    showAndOpenSideBar(state) {
      state.isCollapsed = true;
      state.isOpen = true;
    },
    closeAndCollapseSidebar(state) {
      state.isCollapsed = false;
      state.isOpen = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getMenuItems.fulfilled, (state, action) => ({
      ...state,
      menuItems: action.payload.data,
      isLoadingMenu: true,
    }));
    builder.addCase(getMenuItems.pending, (state, action) => ({
      ...state,
      isLoadingMenu: true,
    }));
  },
});

// Action Creators генерируются для каждого reducer и case
export const { openOrCloseSidebar, showOrCollapseSidebar, showAndOpenSideBar, closeAndCollapseSidebar } = sidebarSlice.actions;

// Reducer
export default sidebarSlice.reducer;

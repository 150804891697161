export interface IMdlBalanceModel {
  id?: number;
  docid?: number | null;
  mnem?: string | null;
  objectname?: string | null;
  loading_scen?: number | null;
  distribution_scen?: number | null;
  shipper_scen?: number | null;
  verified_requests?: boolean | null;
  base_year?: number | null;
  bearing_years?: Array<number> | null;
  begdate?: Date | null;
  enddate?: Date | null;
}

export const defaultValue: Readonly<IMdlBalanceModel> = {};

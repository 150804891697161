import { createSlice } from '@reduxjs/toolkit';
import { defaultValue } from 'models/IMdlBalanceModel.model';

export const contextModelSlice = createSlice({
  name: 'contextModel',
  initialState: {
    currentModel: defaultValue,
    toggle: true,
  },
  reducers: {
    setContextModel(state, action) {
      state.currentModel = action.payload;
    },
    resetContextModel(state) {
      state.currentModel = defaultValue;
    },
    toggleContextModel(state) {
      state.toggle = !state.toggle;
    },
  },
});

// Action Creators генерируются для каждого reducer и case
export const { setContextModel, resetContextModel, toggleContextModel } = contextModelSlice.actions;

// Reducer
export default contextModelSlice.reducer;

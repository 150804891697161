import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import PageNotFound from 'shared/error/PageNotFound';
import ErrorBoundaryRoute from 'shared/error/ErrorBoundaryRoute';
import ArmPage from 'pages/Arm/ArmPage';
import LogoutPage from 'pages/Logout/Logout';
import PrivateRoute from 'shared/auth/PrivateRoute';
import { AUTHORITIES } from 'config/Constants';
import Preloader from 'common/Preloader';
import Authorization from 'components/RootCode/Progruz/pages/Authorization';

const Operator = lazy(() => import('components/Operator/OperatorRoutes'));
const Analyst = lazy(() => import('components/Analyst/AnalystRoutes'));
const Progruz = lazy(() => import('components/RootCode/Progruz/ProgruzRoutes'));
const Eim = lazy(() => import('components/RootCode/Eim/EimRoutes'));

const Routes = () => (
  <Suspense
    fallback={
      <div style={{ position: 'relative', top: '50%', textAlign: 'center' }}>
        <Preloader color="red" />
      </div>
    }
  >
    <Switch>
      <ErrorBoundaryRoute path="/login" component={Authorization} />
      <ErrorBoundaryRoute path="/logout" component={LogoutPage} />

      <PrivateRoute
        path="/operator"
        component={Operator}
        hasAnyAuthorities={[
          AUTHORITIES.OPERATOR,
          AUTHORITIES.ANALYST,
          AUTHORITIES.GVC_PROGRUZ_Admin,
          AUTHORITIES.GVC_PROGRUZ_Analyst,
          AUTHORITIES.GVC_PROGRUZ_Operator,
          AUTHORITIES.MAIN_PROGRUZ_Admin,
          AUTHORITIES.MAIN_PROGRUZ_Analyst,
          AUTHORITIES.MAIN_PROGRUZ_Operator,
          AUTHORITIES.ORG_PROGRUZ_Admin,
          AUTHORITIES.ORG_PROGRUZ_Analyst,
          AUTHORITIES.ORG_PROGRUZ_Operator,
        ]}
      />
      <PrivateRoute
        path="/analyst"
        component={Analyst}
        hasAnyAuthorities={[
          AUTHORITIES.OPERATOR,
          AUTHORITIES.ANALYST,
          AUTHORITIES.GVC_PROGRUZ_Analyst,
          AUTHORITIES.GVC_PROGRUZ_Operator,
          AUTHORITIES.MAIN_PROGRUZ_Admin,
          AUTHORITIES.MAIN_PROGRUZ_Analyst,
          AUTHORITIES.MAIN_PROGRUZ_Operator,
          AUTHORITIES.ORG_PROGRUZ_Admin,
          AUTHORITIES.ORG_PROGRUZ_Analyst,
          AUTHORITIES.ORG_PROGRUZ_Operator,
        ]}
      />
      <PrivateRoute
        path="/eim"
        component={Eim}
        hasAnyAuthorities={[AUTHORITIES.GVC_PROGRUZ_Admin, AUTHORITIES.GVC_PROGRUZ_Expert, AUTHORITIES.GVC_PROGRUZ_View]}
      />
      <PrivateRoute
        path="/progruz"
        component={Progruz}
        hasAnyAuthorities={[AUTHORITIES.GVC_PROGRUZ_Admin, AUTHORITIES.GVC_PROGRUZ_Expert, AUTHORITIES.GVC_PROGRUZ_View]}
      />

      <ErrorBoundaryRoute exact path="/" component={ArmPage} />
      <ErrorBoundaryRoute path="/*" component={PageNotFound} />
    </Switch>
  </Suspense>
);

export default Routes;

import React, { ComponentProps } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'config/Store';
import ErrorBoundary from 'shared/error/ErrorBoundary';
import PageNotAccessible from 'shared/error/PageNotAccessible';

interface IPrivateRoute extends RouteProps {
  hasAnyAuthorities?: string[];
  renderProps?: ComponentProps<any>;
  isInfoAlert?: boolean;
  isDocAlert?: boolean;
  isFucked?: boolean;
}

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some((auth) => authorities.includes(auth));
  }
  return false;
};

export const PrivateRouteComponent = ({
  component: Component,
  renderProps = {},
  hasAnyAuthorities = [],
  isInfoAlert,
  isDocAlert,
  ...rest
}: IPrivateRoute) => {
  const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
  const account = useAppSelector((state) => state.authentication.account);
  const isAuthorized = hasAnyAuthority(account.authorities, hasAnyAuthorities);

  const checkAuthorities = (props) =>
    isAuthorized ? (
      <ErrorBoundary>
        {/* {renderProps?.isInfoAlert && <CurrentContext />}
        {renderProps?.isDocAlert && <SelectedDoc />} */}
        {/* @ts-ignore */}
        <Component {...renderProps} {...props} />
      </ErrorBoundary>
    ) : (
      <PageNotAccessible />
    );

  const renderRedirect = (props) => {
    const isAuth = props.location.pathname.includes('/progruz/scenario') ? true : isAuthenticated;

    return isAuth ? (
      checkAuthorities(props)
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          search: props.location.search,
          state: { from: props.location },
        }}
      />
    );
  };

  if (!Component) throw new Error(`Для private route необходимо указать компонент для пути ${(rest as any).path}`);

  return (
    <>
      {/* {renderProps?.isInfoAlert && <CurrentContext />} */}
      {/* {renderProps?.isDocAlert && <SelectedDoc />} */}
      <Route {...rest} render={renderRedirect} />
    </>
  );
};

/**
 * React Route, обернутый в проверку подлинности
 * Маршрутизация происходит только тогда, когда пользователь аутентифицирован
 * Принимает те же реквизиты, что и React router Route
 * Маршрут также проверяет авторизацию, если указано hasAnyAuthorities
 */
export default PrivateRouteComponent;

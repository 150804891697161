import { ARMS, AUTHORITIES } from 'config/Constants';

const SideBarLinks = [
  {
    link: '/operator/fact',
    text: 'Факт',
    roles: [AUTHORITIES.OPERATOR],
    arms: [ARMS.OPERATOR],
    icon: 'database',
    external: false,
    enabled: true,
    children: [
      {
        link: '/operator/fact/form-1',
        text: 'Форма А-1. Фактические объемы погрузки на сеть дорог ОАО «РЖД»',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
    ],
  },
  {
    link: '/operator/request',
    text: 'Заявки',
    roles: [AUTHORITIES.OPERATOR],
    arms: [ARMS.OPERATOR],
    icon: 'fas fa-clipboard-list',
    external: false,
    enabled: true,
    children: [
      {
        link: '/operator/request/form-3',
        text: 'Форма А-3. Заявленные объемы перевозок по сети дорог ОАО «РЖД»',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/request/form-4',
        text: 'Форма А-4. Форма корректировки заявленных объемов перевозки',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
    ],
  },
  {
    link: '/operator/scen',
    text: 'Внешние сценарии балансировки',
    roles: [AUTHORITIES.OPERATOR],
    icon: 'database',
    arms: [ARMS.OPERATOR],
    external: false,
    enabled: true,
    children: [
      {
        link: '/operator/scen/form-2',
        text: 'Форма А-2. Целевые объемы погрузки на сети дорог ОАО «РЖД»',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/scen/form-5',
        text: 'Форма А-5. Форма загрузки сценариев по направлениям',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
    ],
  },

  {
    link: '/operator/modeling',
    text: 'Моделирование',
    roles: [AUTHORITIES.OPERATOR],
    icon: 'database',
    arms: [ARMS.OPERATOR],
    external: false,
    enabled: true,
    children: [
      {
        link: '/operator/modeling/econometric_scen',
        text: 'Макроэкономические сценарии',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/modeling/shipper_scen',
        text: 'Настройка сценария грузоотправителей',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/modeling/model-settings',
        text: 'А61. Настройка модели',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },

      {
        link: '/operator/modeling/calc-loading-by-shipper',
        text: 'А62. Балансировка объемов погрузки по сети ОАО «РЖД»',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/modeling/calc-loading-by-cargo-flows',
        text: 'А63. Балансировка погрузки по местам зарождения потоков',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/modeling/forecast-shipper-loading-by-directions',
        text: 'А64. Распределение погрузки по направлениям',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/modeling/load-balancing-by-directions',
        text: 'А65. Балансировка погрузки по направлениям',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/modeling/redemption-cargo-flows-of-shippers',
        text: 'А66. Определение мест погашения грузопотоков грузоотправителей',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/modeling/loading-corr-shippers',
        text: 'А67. Формирование корреспонденций погрузки для основных грузоотправителей',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
    ],
  },

  {
    link: '/operator/output-form',
    text: 'Выходные формы',
    roles: [AUTHORITIES.OPERATOR],
    icon: 'file-export',
    arms: [ARMS.OPERATOR, ARMS.ANALYST],
    external: false,
    enabled: true,
    children: [
      {
        link: '/operator/output-form/b-1',
        text: 'Форма Б-1. Прогноз погрузки по грузоотправителям',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/output-form/b-2',
        text: 'Форма Б-2. Прогноз погрузки по грузоотправителям в разрезе дорог',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/output-form/b-3',
        text: 'Форма Б-3. Прогноз распределения погрузки по направлениям',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/output-form/b-4',
        text: 'Форма Б-4. Прогноз сбалансированного распределения погрузки по направлениям',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/output-form/b-5',
        text: 'Форма Б-5. Прогноз сбалансированного распределения погрузки по дорогам назначения',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/output-form/b-6',
        text: 'Форма Б-6. Корреспонденции погрузки для основных грузоотправителей',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
    ],
  },
  {
    link: '/operator/nsi',
    text: 'НСИ',
    roles: [AUTHORITIES.OPERATOR],
    icon: 'book',
    arms: [ARMS.OPERATOR, ARMS.ANALYST],
    external: false,
    enabled: true,
    children: [
      {
        link: '/operator/nsi/shipper',
        text: 'Грузоотправители',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/nsi/holding',
        text: 'Холдинг',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
      {
        link: '/operator/nsi/direction',
        text: 'Укрупненные направления погрузки',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
    ],
  },
  {
    link: '/eim',
    text: 'Сценарии',
    roles: [AUTHORITIES.OPERATOR],
    icon: 'database',
    arms: [ARMS.EIM],
    external: false,
    enabled: true,
    children: [
      {
        link: '/eim/list',
        text: 'Прогнозные сценарии объемов перевозок грузов',
        icon: 'caret-right',
        external: false,
        enabled: true,
      },
    ],
  },
  {
    link: '/progruz/application/0',
    text: 'Данные по заявкам',
    roles: [AUTHORITIES.OPERATOR],
    icon: 'database',
    arms: [ARMS.PROGRUZ],
    external: false,
    enabled: true,
    children: [],
  },
  {
    link: '/progruz/list',
    text: 'Прогнозные сценарии объемов перевозок грузов',
    roles: [AUTHORITIES.OPERATOR],
    icon: 'database',
    arms: [ARMS.PROGRUZ],
    external: false,
    enabled: true,
    children: [],
  },

  // ------------------------ АРМ Реестр автомобильных дорог ---------------------------

  // {
  //   link: '/hwm/auto-context',
  //   text: 'Выбор активной дороги и направления',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.HWM],
  //   icon: 'road',
  //   external: false,
  //   children: [],
  //   enabled: true,
  // },
  // {
  //   link: '/hwm/auto-sections',
  //   text: 'Паспорта дорог',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.HWM],
  //   icon: 'border-all',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/hwm/auto-sections/drivewaysegments',
  //       text: 'Проезжая часть',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/hwm/auto-roads-construction',
  //   text: 'Паспорта сооружений',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.HWM],
  //   icon: 'warehouse',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/hwm/auto-roads-construction/exit-additional-segments',
  //       text: 'Дополнительные участки съезда',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/hwm/auto-roadworks',
  //   text: 'Дорожные работы',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.HWM],
  //   icon: 'hard-hat',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/hwm/auto-roadworks/maintanance',
  //       text: 'Содержание',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     {
  //       link: '/hwm/auto-roadworks/repairs',
  //       text: 'Ремонты',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/hwm/auto-roadworks/reconstructions-constructions',
  //       text: 'Реконструкции и строительство',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/hwm/auto-diagnostics',
  //   text: 'Диагностика',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.HWM],
  //   icon: 'chart-bar',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/hwm/auto-diagnostics/evenness',
  //       text: 'Ровность',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/hwm/auto-diagnostics/rut',
  //       text: 'Колейность',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/hwm/auto-diagnostics/cohesion',
  //       text: 'Сцепление',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/hwm/auto-logical-sections',
  //   text: 'Сети автомобильных дорог',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.HWM],
  //   icon: 'code-branch',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/hwm/auto-logical-sections/network-link',
  //       text: 'Участки сети дорог ГК «Автодор»',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/hwm/auto-isso',
  //   text: 'Реестр дорожных ИССО',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.HWM],
  //   icon: 'archway',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/hwm/auto-isso/isso-registry',
  //       text: 'Дорожные сооружения ИССО',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/hwm/auto-nsi',
  //   text: 'Справочники (НСИ)',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.HWM],
  //   icon: 'book-open',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/hwm/auto-nsi/roads',
  //       text: 'Автомобильные дороги',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/hwm/auto-nsi/directions',
  //       text: 'Направления',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/hwm/auto-nsi/axis',
  //       text: 'Оси',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/hwm/auto-nsi/nodes',
  //       text: 'Узлы дорожной сети',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     {
  //       link: '/hwm/auto-nsi/sections',
  //       text: 'Участки дорожной сети',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //   ],
  // },
  // // ------------------------ АРМ Интерактивная карта ---------------------------
  // {
  //   link: '/map/map-diagnostic',
  //   text: 'Диагностика',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.MAP],
  //   icon: 'map-marked-alt',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/map/map-diagnostic/iri_prev',
  //       text: 'Ровность (предыдущий вариант)',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     {
  //       link: '/map/map-diagnostic/iri',
  //       text: 'Ровность',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/map/map-diagnostic/grip',
  //       text: 'Сцепление',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     {
  //       link: '/map/map-diagnostic/defects',
  //       text: 'Дефекты',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //   ],
  // },
  // {
  //   link: '/map/map-works',
  //   text: 'Дорожные работы',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.MAP],
  //   icon: 'road',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/map/map-works/guarantee',
  //       text: 'Участки проведения работ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/map/map-auto-logical-sections',
  //   text: 'Логические участки',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.MAP],
  //   icon: 'road',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/map/map-auto-logical-sections/network',
  //       text: 'Участки сети дорог ГК «Автодор»',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/map/map-isso',
  //   text: 'Дорожные ИССО',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.MAP],
  //   icon: 'archway',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/map/map-isso/isso',
  //       text: 'Дорожные ИССО',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/map/map-contractors',
  //   text: 'Подрядчики',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.MAP],
  //   icon: 'user-tie',
  //   external: false,
  //   enabled: false,
  //   children: [],
  // },
  // {
  //   link: '/map/map-table',
  //   text: 'Инструмент "таблица"',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.TESTER, AUTHORITIES.DEV],
  //   arms: [ARMS.MAP],
  //   icon: 'table',
  //   external: false,
  //   enabled: false,
  //   children: [],
  // },

  // // ------------------------ АРМ Дорожные работы ---------------------------

  // // ------------------------ АРМ Планирование дорожных работ ---------------------------
  // {
  //   link: '/pln/investment-program',
  //   text: 'Инвестпрограмма',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.PLN],
  //   icon: 'calendar-alt',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/pln/investment-program/objects',
  //       text: 'Классификатор объектов развития',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     {
  //       link: '/pln/investment-program/nomenclature',
  //       text: 'Классификатор работ развития',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     {
  //       link: '/pln/investment-program/stages',
  //       text: 'Классификатор стадий работ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     {
  //       link: '/pln/investment-program/versions-plan-kilometers',
  //       text: 'Версии плана ввода километров',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/investment-program/invest',
  //       text: 'План ввода километров',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/pln/renovation-plan',
  //   text: 'План ремонтов',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.PLN],
  //   icon: 'calendar-alt',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/pln/renovation-plan/objects-classificator',
  //       text: 'Классификатор объектов ремонта',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     // {
  //     //   link: '/pln/renovation-plan/rep-caprep-classificator',
  //     //   text: 'Классификатор работ по ремонту',
  //     //   icon: 'caret-right',
  //     //   external: false,
  //     //   enabled: true,
  //     // },
  //     {
  //       link: '/pln/renovation-plan/versions-plan-repairs',
  //       text: 'Версии плана ремонтов',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/renovation-plan/plan',
  //       text: 'План ремонтов',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/pln/renovation-plan-isso',
  //   text: 'План ремонтов ИССО',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.PLN],
  //   icon: 'calendar-alt',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/pln/renovation-plan/objects-classificator',
  //       text: 'Классификатор объектов ремонта',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     // {
  //     //   link: '/pln/renovation-plan/rep-caprep-classificator',
  //     //   text: 'Классификатор работ по ремонту',
  //     //   icon: 'caret-right',
  //     //   external: false,
  //     //   enabled: true,
  //     // },
  //     {
  //       link: '/pln/renovation-plan-isso/versions-plan-repairs',
  //       text: 'Версии плана ремонтов ИССО',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/renovation-plan-isso/plan',
  //       text: 'План ремонтов ИССО',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/pln/maintenance-plan',
  //   text: 'План содержания',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.PLN],
  //   icon: 'calendar-alt',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/pln/maintenance-plan/sections',
  //       text: 'Участки планирования содержания',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/maintenance-plan/objects-classification',
  //       text: 'Классификатор объектов содержания',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/maintenance-plan/worktype-frequency',
  //       text: 'Состав и периодичность выполнения работ по содержанию',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/maintenance-plan/characteristics',
  //       text: 'Номенклатура, количественные и площадные характеристики автомобильных дорог',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-statement-vol',
  //       text: 'Ведомость объемов работ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/maintenance-plan/versions-plan',
  //       text: 'Версии плана содержания',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/maintenance-plan/plan',
  //       text: 'План содержания',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/pln/roadwork-planning-planning',
  //   text: 'Расчёт бюджетных ассигнований',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.PLN],
  //   icon: 'book',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     // {
  //     //   link: '/pln/roadwork-planning-planning/scenario',
  //     //   text: 'Настройка сценария расчёта',
  //     //   icon: 'caret-right',
  //     //   external: false,
  //     //   enabled: true,
  //     // },
  //     {
  //       link: '/pln/roadwork-planning-planning/initial-data',
  //       text: 'Входные данные для расчета бюджетных ассигнований',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-planning/budget-repair-cap',
  //       text: 'Бюджетные ассигнования на капитальный ремонт',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-planning/budget-repair',
  //       text: 'Бюджетные ассигнования на ремонт',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-planning/budget-maintanance',
  //       text: 'Бюджетные ассигнования на содержание',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/pln/roadwork-planning-category-cost',
  //   text: 'Нормативы финансовых затрат',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.PLN],
  //   icon: 'book',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/pln/roadwork-planning-category-cost/doc',
  //       text: 'Руководящий документ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-category-cost/basic',
  //       text: 'Базовые нормативы финансовых затрат',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-category-cost/annex1',
  //       text: 'Приложение 1',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-category-cost/annex2',
  //       text: 'Приложение 2',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-category-cost/annex3',
  //       text: 'Приложение 3',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-category-cost/annex4',
  //       text: 'Приложение 4',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-category-cost/deflator',
  //       text: 'Индекс-дефлятор',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/pln/roadwork-planning-nsi',
  //   text: 'НСИ АРМ «Планирование»',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.PLN],
  //   icon: 'book',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/pln/roadwork-planning-nsi/classificators',
  //       text: 'Классификаторы работ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-nsi/class',
  //       text: 'Виды работ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-nsi/types',
  //       text: 'Состав работ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/pln/roadwork-planning-docs',
  //   text: 'Документы',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.PLN],
  //   icon: 'book',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/pln/roadwork-planning-docs/commission',
  //       text: 'Акты ввода в эксплуатацию',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-docs/permission',
  //       text: 'Разрешения на ввод в эксплуатацию ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-docs/toll',
  //       text: 'Документы-основания переводов в платность',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/pln/roadwork-planning-docs/conclusion',
  //       text: 'Заключения о соответствии (ЗОС)',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // // ------------------------ АРМ Реестр договоров и соглашений ---------------------------
  // {
  //   link: '/ctr/contracts-register-contragents',
  //   text: 'Реестр контрагентов',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.CTR],
  //   icon: 'file-contract',
  //   external: false,
  //   enabled: true,
  //   children: [],
  // },
  // {
  //   link: '/ctr/contracts-register-contracts-control',
  //   text: 'Управление договорами',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.CTR],
  //   icon: 'file-contract',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/ctr/contracts-register/contracts',
  //       text: 'Договоры',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-contracts-control/acts',
  //       text: 'Акты выполненных работ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-contracts-control/annex',
  //       text: 'Приложения к актам выполненных работ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     {
  //       link: '/ctr/contracts-register-contracts-control/warranty-passports',
  //       text: 'Гарантийные паспорта',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-contracts-control/banks-guarantees',
  //       text: 'Банковские гарантии',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     {
  //       link: '/ctr/contracts-register-contracts-control/avoidances',
  //       text: 'Расторжения',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //   ],
  // },
  // {
  //   link: '/ctr/contracts-register-monitoring',
  //   text: 'Мониторинг исполнения договоров',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.CTR],
  //   icon: 'file-contract',
  //   external: false,
  //   enabled: false,
  //   children: [
  //     {
  //       link: '/ctr/contracts-register-monitoring/states',
  //       text: 'Состояние расчетов по договорам',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-monitoring/defects',
  //       text: 'Дефекты содержания',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-monitoring/penalties',
  //       text: 'Штрафы по договорам содержания',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/ctr/contracts-register-payment',
  //   text: 'Приемка и оплата',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.CTR],
  //   icon: 'file-contract',
  //   external: false,
  //   enabled: false,
  //   children: [
  //     {
  //       link: '/ctr/contracts-register-payment/submission-statements',
  //       text: 'Подача ведомостей объемов работ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-payment/focus-statements',
  //       text: 'Акцепт ведомостей объемов работ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-payment/presentation-payment-requests',
  //       text: 'Оформление платежных требований',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-payment/focus-payment-requests',
  //       text: 'Акцепт платежных требований',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-payment/payment-control',
  //       text: 'Контроль приемки и оплаты',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/ctr/contracts-register-nsi',
  //   text: 'НСИ',
  //   roles: [AUTHORITIES.ADMINISTRATION],
  //   arms: [ARMS.CTR],
  //   icon: 'file-contract',
  //   external: false,
  //   enabled: false,
  //   children: [
  //     {
  //       link: '/ctr/contracts-register-nsi/contracts-state',
  //       text: 'Состояние документа',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-nsi/cfu',
  //       text: 'ЦФУ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/ctr/contracts-register-nsi/cfo',
  //       text: 'ЦФО',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // // ------------------------ АРМ Аналитика и отчетность ---------------------------
  // {
  //   link: '/rpt/analyst-interactive-reports',
  //   text: 'Интерактивные отчеты',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.DEPARTMENT],
  //   arms: [ARMS.RPT],
  //   icon: 'comment-alt',
  //   external: false,
  //   enabled: false,
  //   children: [],
  // },
  // {
  //   link: '/rpt/analyst-standard-reports',
  //   text: 'Стандартные отчеты',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.DEPARTMENT],
  //   arms: [ARMS.RPT],
  //   icon: 'comment-alt',
  //   external: false,
  //   enabled: false,
  //   children: [],
  // },
  // {
  //   link: '/rpt/analyst/statform',
  //   text: 'Статистические формы',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.DEPARTMENT],
  //   arms: [ARMS.RPT],
  //   icon: 'book',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/rpt/analyst/statform/dg-1',
  //       text: 'Форма 1-ДГ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/rpt/analyst/statform/dg-1-annex',
  //       text: 'Приложение к форме 1-ДГ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/rpt/analyst/statform/dg-2',
  //       text: 'Форма 2-ДГ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/rpt/analyst/statform/dg-2-annex',
  //       text: 'Приложение к форме 2-ДГ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/rpt/analyst/statform/avtodor-7',
  //       text: 'Форма 7-Автодор',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/rpt/analyst-statform-settings',
  //   text: 'Настройка статформ',
  //   roles: [AUTHORITIES.ADMINISTRATION, AUTHORITIES.DEPARTMENT],
  //   arms: [ARMS.RPT],
  //   icon: 'cog',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/rpt/analyst-statform-settings',
  //       text: 'Настройка статформ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: false,
  //     },
  //     {
  //       link: '/rpt/analyst-statform-settings-sectors',
  //       text: 'Участки статистического учета',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/rpt/analyst-statform-1-dg',
  //       text: 'Показатели 1-ДГ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/rpt/analyst-statform-2-dg',
  //       text: 'Показатели 2-ДГ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/rpt/analyst-statform-7-avtodor',
  //       text: 'Показатели 7-автодор',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/rpt/analyst-statform-settings-title',
  //       text: 'Настройка титула статформ',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/rpt/analyst-statform-settings-respondent',
  //       text: 'Настройка респондента',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // // ------------------------ АРМ Администратора ---------------------------
  // {
  //   link: '/adm/user-management',
  //   text: 'Пользователи',
  //   roles: [AUTHORITIES.ADMIN],
  //   arms: [ARMS.ADM],
  //   icon: 'users',
  //   external: false,
  //   enabled: true,
  //   children: [],
  // },
  // {
  //   link: '/adm/configs-management',
  //   text: 'Управление конфигурацией',
  //   roles: [AUTHORITIES.ADMIN],
  //   arms: [ARMS.ADM],
  //   icon: 'cog',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/adm/configs-management/config',
  //       text: 'Конфигурация',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/adm/configs-management/attrs',
  //       text: 'Конфигурация атрибутов',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/adm/configs-management/doctypes',
  //       text: 'Конфигурация документов',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/adm/configs-management/menuitems',
  //       text: 'Конфигурация меню',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/adm/configs-management/objects',
  //       text: 'Конфигурация объектов',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // // ------------------------ АРМ НСИ ---------------------------
  // {
  //   link: '/nsi/nsi-system',
  //   text: 'Системные НСИ',
  //   roles: [AUTHORITIES.ADMIN],
  //   arms: [ARMS.ADM, ARMS.NSI],
  //   icon: 'book-open',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/nsi/nsi-system/road',
  //       text: 'Автомобильная дорога',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/nsi/nsi-system/direction',
  //       text: 'Направления',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/nsi/nsi-system/axis',
  //       text: 'Оси',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/nsi/nsi-system/contacts',
  //       text: 'Контакты',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/nsi/nsi-system/contragents',
  //       text: 'Контрагенты',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/nsi/nsi-system/departments',
  //       text: 'Структурные подразделения',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   link: '/nsi/nsi-custom',
  //   text: 'Пользовательские НСИ',
  //   roles: [AUTHORITIES.ADMIN],
  //   arms: [ARMS.ADM, ARMS.NSI],
  //   icon: 'book-reader',
  //   external: false,
  //   enabled: true,
  //   children: [
  //     {
  //       link: '/nsi/nsi-custom/connection',
  //       text: 'Подключение',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/nsi/nsi-custom/dic',
  //       text: 'Справочники',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //     {
  //       link: '/nsi/nsi-custom/links',
  //       text: 'Связь справочников',
  //       icon: 'caret-right',
  //       external: false,
  //       enabled: true,
  //     },
  //   ],
  // },
  // // ------------------------ АРМ Журналы и аудит ---------------------------
  // {
  //   link: '/log/audit-diagnostic',
  //   text: 'Диагностика работоспособности',
  //   roles: [AUTHORITIES.ADMIN],
  //   // arms: [ARMS.ADM, ARMS.LOG],
  //   arms: [ARMS.LOG],
  //   icon: 'comment-alt',
  //   external: false,
  //   enabled: true,
  //   children: [],
  // },
  // {
  //   link: '/log/audit-actions',
  //   text: 'Аудит действий пользователей',
  //   roles: [AUTHORITIES.ADMIN],
  //   // arms: [ARMS.ADM, ARMS.LOG],
  //   arms: [ARMS.LOG],
  //   icon: 'comment-alt',
  //   external: false,
  //   enabled: true,
  //   children: [],
  // },
  // {
  //   link: '/log/audit-components-journals',
  //   text: 'Журналы компонентов приложения',
  //   roles: [AUTHORITIES.ADMIN],
  //   // arms: [ARMS.ADM, ARMS.LOG],
  //   arms: [ARMS.LOG],
  //   icon: 'comment-alt',
  //   external: false,
  //   enabled: true,
  //   children: [],
  // },
  // // ------------------------ АРМ Интеграция с внешними системами ---------------------------
  // {
  //   link: '/ext/external-systems-gis',
  //   text: 'Интеграция с ГИС автодорог',
  //   roles: [AUTHORITIES.ADMIN],
  //   arms: [ARMS.EXT],
  //   icon: 'comment-alt',
  //   external: false,
  //   enabled: true,
  //   children: [],
  // },
  // {
  //   link: '/ext/external-systems-kurator',
  //   text: 'Интеграция с КУРАТОР',
  //   roles: [AUTHORITIES.ADMIN],
  //   arms: [ARMS.EXT],
  //   icon: 'comment-alt',
  //   external: false,
  //   enabled: true,
  //   children: [],
  // },
  // {
  //   link: '/ext/external-systems-doc-flow',
  //   text: 'Интеграция с документооборотом',
  //   roles: [AUTHORITIES.ADMIN],
  //   arms: [ARMS.EXT],
  //   icon: 'comment-alt',
  //   external: false,
  //   enabled: true,
  //   children: [],
  // },
];

export default SideBarLinks;

import React from 'react';
import { MDBCard, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'config/Store';
import { resetContextModel, toggleContextModel } from 'reducers/ContextModel.reducer';
import { IconButton, Tooltip } from '@material-ui/core';
import style from 'common/CurrentContext/currentcontext.module.css';

const CurrentContext = () => {
  const dispatch = useAppDispatch();
  const context = useAppSelector((state) => state.contextModel.currentModel);
  const docTabletIsVisible = useAppSelector((state) => state.contextModel.toggle);
  const renderCondition = context && Object.keys(context).length > 0;
  const history = useHistory();

  return renderCondition ? (
    <MDBCard
      style={{
        zIndex: 888,
        padding: '10px',
        position: 'absolute',
        right: '15px',
        top: '60px',
        backgroundColor: '#000',
        maxWidth: '400px',
        color: '#fff',
        opacity: 0.7,
        border: '1px solid #000',
        fontSize: '12px',
      }}
    >
      <MDBContainer fluid style={{ padding: '0px' }}>
        <MDBRow>
          <MDBCol size="1" style={{ textAlign: 'center', padding: '0px 10px 0px 10px' }}>
            <div
              style={{
                position: 'relative',
                top: '50%',
                marginTop: '-12px',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(toggleContextModel());
              }}
              className={docTabletIsVisible ? style.blink : ''}
            >
              <span>
                {/* <Tooltip title="Развернуть/свернуть" disableFocusListener disableTouchListener style={{ padding: '0px', margin: '0px' }}>
                <IconButton style={{ margin: '0px', padding: '0px', cursor: 'pointer' }}> */}
                <MDBIcon
                  icon="info-circle"
                  // size="2x"
                  style={{
                    cursor: 'pointer',
                    fontSize: '24px',
                  }}
                  className="cyan-text"
                />
                {/* </IconButton>
              </Tooltip> */}
              </span>
            </div>
          </MDBCol>
          <MDBCol size="10" style={{ display: !docTabletIsVisible ? 'block' : 'none', padding: '0px 15px 0px 15px', minWidth: '250px' }}>
            <span className="cyan-text" style={{ fontWeight: 600 }}>
              Выбранный контекст (модель)
            </span>
            <div style={{ fontWeight: 400, marginTop: '0px' }}>Модель прогнозирования: {context.objectname}</div>
            <div style={{ fontWeight: 400, marginTop: '0px' }}>Базовый год: {context.base_year}</div>
          </MDBCol>
          <MDBCol size="1" style={{ textAlign: 'center', display: !docTabletIsVisible ? 'block' : 'none', padding: '0px 5px 0px 0px' }}>
            <div style={{ position: 'relative', marginTop: '0px' }}>
              <Tooltip title="Сброс" disableFocusListener disableTouchListener style={{ padding: '0px', margin: '0px' }}>
                <IconButton style={{ margin: '0px', padding: '0px' }}>
                  <MDBIcon
                    onClick={() => {
                      dispatch(resetContextModel());
                      history.push('/operator/modeling/model-settings/redirect');
                    }}
                    style={{ fontSize: '16px', cursor: 'pointer', color: '#CC0000' }}
                    // className="danger-dark-text"
                    icon="minus-circle"
                    // sizetitle="Сброс"
                  />
                </IconButton>
              </Tooltip>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBCard>
  ) : null;
};

export default CurrentContext;

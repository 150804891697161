import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getJwtToken } from 'util/Storage.util';

// export const myApiPath =
//   process.env.NODE_ENV === 'development'
//     ? process.env.REACT_APP_API_PATH
//     : // eslint-disable-next-line no-undef
//       `${document.location.protocol}//${document.location.host}/v2/api`;
// export const myApiPath = `${document.location.protocol}//${document.location.host}/v2/api`;

const appAxios = axios.create({
  baseURL: '/v2/api/services/backend2',
  headers: { Prefer: 'count=exact, return=representation' },
});

const onRequestSuccess = (config: AxiosRequestConfig) => {
  const token = getJwtToken();
  if (token) {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const onResponseSuccess = (response) => response;
const onResponseError = (err: AxiosError) => Promise.reject(err);

appAxios.interceptors.request.use(onRequestSuccess);
appAxios.interceptors.response.use(onResponseSuccess, onResponseError);

export default appAxios;

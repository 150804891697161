import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'config/Store';
import { changePasswordV3, loginV3 } from 'reducers/Authentication.reducer';
import { checkArmFromUrl } from 'reducers/Config.reducer';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { AuthForm } from '../components/Authorization/AuthForm';
import '../assets/styles/main.scss';
import { ChangePasswordForm } from '../components/Authorization/ChangePasswordForm';

function Authorization(props: RouteComponentProps<any>) {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
  const userId = useAppSelector((state) => state.authentication.accountV3?.RIGHTS?.[0]?.user_id);
  const loginError = useAppSelector((state) => state.authentication.loginError);
  const needChangePassword = useAppSelector((state) => state.authentication.needChangePassword);
  const showModalLogin = useAppSelector((state) => state.authentication.showModalLogin);
  const [showModal, setShowModal] = useState(showModalLogin);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleLogin = (username, password, isNeedToChangePassword) => dispatch(loginV3(username, password, isNeedToChangePassword));

  const handleChangePassword = (password) => dispatch(changePasswordV3(userId, password));

  const handleClose = () => {
    setShowModal(false);
    props.history.push('/');
  };

  const { location } = props;
  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };

  if (isAuthenticated && !needChangePassword) {
    dispatch(checkArmFromUrl(from));
    return <Redirect to={from} />;
  }

  return (
    <div className="rootcode-progruz full-height">
      <div className="authorization">
        {!needChangePassword ? (
          <AuthForm handleLogin={handleLogin} loginError={loginError} />
        ) : (
          <ChangePasswordForm handleChangePassword={handleChangePassword} />
        )}
      </div>
    </div>
  );
}

export default Authorization;

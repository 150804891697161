import React, { ComponentProps } from 'react';
import CurrentContext from 'common/CurrentContext/CurrentContext';
import { Route, RouteProps } from 'react-router-dom';
import ErrorBoundary from 'shared/error/ErrorBoundary';

interface IErrorBoundaryRoute extends RouteProps {
  renderProps?: ComponentProps<any>;
  isInfoAlert?: boolean;
  isDocAlert?: boolean;
}

export const ErrorBoundaryRoute = ({ component: Component, renderProps, ...rest }: IErrorBoundaryRoute) => {
  const encloseInErrorBoundary = (props) => (
    <ErrorBoundary>
      {renderProps?.isInfoAlert && <CurrentContext />}
      {/* {renderProps?.isDocAlert && <SelectedDoc />} */}
      {/* @ts-ignore */}
      <Component {...renderProps} {...props} />
    </ErrorBoundary>
  );
  if (!Component) {
    throw new Error(`Компонент должен быть указан для пути ${(rest as any).path}`);
  }

  return (
    <>
      {/* {renderProps?.isInfoAlert && <CurrentContext />}
      {renderProps?.isDocAlert && <SelectedDoc />} */}
      <Route {...rest} render={encloseInErrorBoundary} />
    </>
  );
};

export default ErrorBoundaryRoute;

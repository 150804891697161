export enum StorageType {
  SESSION,
  LOCAL,
}

/**
 * Получить либо localStorage, либо sessionStorage
 * @param type тип хранилища
 */
export const getStorage = (type: StorageType): Storage => {
  if (type === StorageType.SESSION) {
    return window.sessionStorage;
  }
  return window.localStorage;
};

/**
 * Получить либо jwt токен
 */
export const getJwtToken = (): string =>
  Storage.local.get('progruz-authenticationToken') || Storage.session.get('progruz-authenticationToken');

/**
 * Установить элемент в хранилище
 * @param type тип хранилища
 * @param key ключ элемента
 * @param value значение элемента
 */
const setItem = (type: StorageType) => (key: string, value: any) => {
  getStorage(type).setItem(key, JSON.stringify(value));
};

/**
 * Получить элемент из хранилища
 * @param type тип хранилища
 * @param key ключ элемента
 * @param defaultVal значение, которое возвращается, если ключ не существует
 */
const getItem = (type: StorageType) => (key: string, defaultVal?: any) => {
  const val = getStorage(type).getItem(key);
  if (!val || val === 'undefined') return defaultVal;
  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
};

/**
 * Удалить элемент из хранилища
 * @param type тип хранилища
 * @param key ключ для удаления
 */
const removeItem = (type: StorageType) => (key: string) => {
  getStorage(type).removeItem(key);
};

export type GetItemType = (key: string, defaultVal?: any) => any;
export type SetItemType = (key: string, value: any) => void;
export type RemoveItemType = (key: string) => void;

export interface IStorageAPI {
  get: GetItemType;
  set: SetItemType;
  remove: RemoveItemType;
}

export interface IStorageService {
  session: IStorageAPI;
  local: IStorageAPI;
}

export const Storage: IStorageService = {
  session: {
    get: getItem(StorageType.SESSION),
    set: setItem(StorageType.SESSION),
    remove: removeItem(StorageType.SESSION),
  },
  local: {
    get: getItem(StorageType.LOCAL),
    set: setItem(StorageType.LOCAL),
    remove: removeItem(StorageType.LOCAL),
  },
};

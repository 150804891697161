import React, { useState } from 'react';
import {
  MDBBtn,
  MDBCloseIcon,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
} from 'mdbreact';
import { NavLink, useLocation } from 'react-router-dom';
import { openOrCloseSidebar } from 'reducers/Sidebar.reducer';
import { useAppDispatch, useAppSelector } from 'config/Store';
import { hasAnyAuthority } from 'shared/auth/PrivateRoute';
import { AUTHORITIES } from 'config/Constants';
import { GiCargoCrate } from 'react-icons/gi';
import { IconContext } from 'react-icons';
import { ArmsArray } from 'pages/Arm/arms';
import { ThemeProvider } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import { ruRU } from '@material-ui/core/locale';
import { createMuiTheme, TextField } from '@material-ui/core';
import StringMask from 'string-mask';

const Header = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.authentication.account);
  const formatter = new StringMask('+0 (000) 000-00-00');

  const [isAccountModalOpen, setIsAccountModalOpen] = useState<boolean>(false);

  const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
  // TODO: нужно для реализации в header пунктов меню управления системой ИСДР
  const isAdmin = useAppSelector((state) => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN_OLD]));
  const isSideBarCollapsed = useAppSelector((state) => state.sidebar.isCollapsed);

  const config = useAppSelector((state) => state.config.config);
  const currentArm = useAppSelector((state) => state.authentication.currentArm);
  const location = useLocation();
  const currentLocation = location?.pathname;
  let armSplit: any = [];
  if (currentArm && currentArm.length > 0) {
    armSplit = currentArm.split('_');
  }

  const specialCaseNavbarStyles = {
    WebkitBoxOrient: 'horizontal',
    flexDirection: 'row',
  };

  const defaultMaterialTheme = createMuiTheme(
    {
      palette: {
        primary: red,
      },
    },
    ruRU
  );

  const renderSidebarToggler = () =>
    isSideBarCollapsed ? (
      <MDBNavItem>
        <MDBIcon
          icon="bars"
          size="2x"
          style={{ cursor: 'pointer', position: 'relative', zIndex: '1010' }}
          className="white-text mr-4"
          onClick={() => dispatch(openOrCloseSidebar())}
        />
      </MDBNavItem>
    ) : null;

  const renderLoginLogout = () =>
    isAuthenticated ? (
      <>
        <div className="d-flex justify-content-center align-items-center">
          <NavLink to="/" className="pr-xl-1 pr-md-3 pr-sm-3 pr-xs-4">
            <MDBIcon icon="home" />
            <div className="d-none d-xl-inline nav-link" />
          </NavLink>
          <NavLink to="#" className="pr-xl-1 pr-md-3 pr-sm-3 pr-xs-4">
            <MDBIcon icon="user" onClick={() => setIsAccountModalOpen(true)} />
            <div className="d-none d-xl-inline nav-link" />
          </NavLink>
          <NavLink to="/logout">
            <MDBIcon icon="sign-out-alt" />
            <div className="d-none d-xl-inline nav-link ml-0">Выйти</div>
          </NavLink>
        </div>
      </>
    ) : (
      <NavLink to="/login">
        <MDBIcon icon="sign-in-alt" />
        <div className="d-none d-xl-inline nav-link ml-0">Войти</div>
      </NavLink>
    );

  return (
    <>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MDBModal
          isOpen={isAccountModalOpen}
          backdrop
          keyboard
          size="md"
          centered
          inline={false}
          noClickableBodyWithoutBackdrop={false}
          overflowScroll={false}
        >
          <MDBModalHeader
            style={{
              backgroundColor: '#273238',
              display: 'block',
              boxShadow: 'none',
              height: '57px',
              padding: '10px 20px 0px 0px',
              fontSize: '14px',
            }}
          >
            <MDBContainer fluid>
              <MDBRow>
                <MDBCol>
                  <span
                    className="float-left"
                    style={{
                      marginTop: '6px',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#cecece',
                      fontFamily: 'Halvar Mittelschrift Regular',
                    }}
                  >
                    Профиль пользователя
                  </span>
                </MDBCol>
                <MDBCol style={{ paddingTop: '5px' }}>
                  <MDBCloseIcon style={{ color: '#ffffff' }} onClick={() => setIsAccountModalOpen(!isAccountModalOpen)} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalHeader>
          <MDBModalBody className="pt-2" style={{ height: 'inherit', padding: '20px' }}>
            {account && Object.keys(account).length > 0 && (
              <>
                <MDBRow>
                  <MDBCol>
                    <label
                      style={{ fontWeight: 500, fontSize: '14px', margin: '0px', padding: '10px 0px 10px 0px', textTransform: 'uppercase' }}
                    >
                      Пользователь
                    </label>
                    <TextField
                      fullWidth
                      style={{ width: '100%', fontSize: '16px' }}
                      disabled
                      value={account?.firstName}
                      variant="outlined"
                      // placeholder="Пользователь"
                      size="small"
                      inputProps={{
                        type: 'text',
                        style: { fontSize: 16, fontFamily: 'Halvar Mittelschrift Regular' },
                      }}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label
                      style={{ fontWeight: 500, fontSize: '14px', margin: '0px', padding: '10px 0px 10px 0px', textTransform: 'uppercase' }}
                    >
                      Номер телефона
                    </label>
                    <TextField
                      fullWidth
                      style={{ width: '100%', fontSize: '16px' }}
                      disabled
                      value={account?.telephoneNumber ? formatter.apply(account?.telephoneNumber) : ''}
                      variant="outlined"
                      //  placeholder="Номер телефона"
                      size="small"
                      inputProps={{
                        type: 'text',
                        style: { fontSize: 16, fontFamily: 'Halvar Mittelschrift Regular' },
                      }}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label
                      style={{ fontWeight: 500, fontSize: '14px', margin: '0px', padding: '10px 0px 10px 0px', textTransform: 'uppercase' }}
                    >
                      Адрес электронной почты
                    </label>
                    <TextField
                      fullWidth
                      style={{ width: '100%', fontSize: '16px' }}
                      disabled
                      value={account?.mail}
                      variant="outlined"
                      // placeholder="Адрес электронной почты"
                      size="small"
                      inputProps={{
                        type: 'text',
                        style: { fontSize: 16, fontFamily: 'Halvar Mittelschrift Regular' },
                      }}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label
                      style={{ fontWeight: 500, fontSize: '14px', margin: '0px', padding: '10px 0px 10px 0px', textTransform: 'uppercase' }}
                    >
                      Подразделение
                    </label>
                    <TextField
                      fullWidth
                      style={{ width: '100%', fontSize: '16px' }}
                      disabled
                      value={account?.department}
                      variant="outlined"
                      // placeholder="Подразделение"
                      size="small"
                      inputProps={{
                        type: 'text',
                        style: { fontSize: 16, fontFamily: 'Halvar Mittelschrift Regular' },
                      }}
                    />
                  </MDBCol>
                </MDBRow>
              </>
            )}
          </MDBModalBody>
          <MDBModalFooter className="justify-content-end py-4">
            <MDBBtn
              color="danger"
              outline
              onClick={() => {
                setIsAccountModalOpen(!isAccountModalOpen);
              }}
            >
              Закрыть
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </ThemeProvider>
      <MDBNavbar double expand="md" color="special-color-dark" dark>
        <MDBNavbarNav left>
          {renderSidebarToggler()}
          {/* <MDBNavItem>
            <NavLink to="/" className="mr-2">
              <MDBIcon className="ml-2" icon="wave-square" size="2x" style={{ color: '#f66b00' }} />
            </NavLink>
          </MDBNavItem> */}

          <MDBNavItem>
            <div
              className="mr-2"
              // style={{ padding: '5px 0px 5px 0px', margin: '0px' }}
            >
              {/* <NavLink to="/"> */}
              <IconContext.Provider
                value={{
                  color: '#e21a1a',
                  className: 'global-class-name',
                  size: '25px',
                }}
              >
                <div>
                  <GiCargoCrate />
                </div>
              </IconContext.Provider>
              {/* </NavLink> */}
            </div>
          </MDBNavItem>

          <MDBNavItem className="white-text" style={{ paddingTop: 3 }}>
            <div className="d-none d-sm-inline font-weight-bolder text-uppercase" style={{ fontSize: '13px' }}>
              <NavLink to="/" style={{ fontSize: '13px' }}>
                АС ПРОГРУЗ
              </NavLink>
              <span style={{ color: '#aeaeae' }}>
                {currentLocation !== '/' &&
                  armSplit?.length > 0 &&
                  ArmsArray?.length > 0 &&
                  ` - ${ArmsArray.filter((item) => item.code === armSplit[1])?.[0]?.name}`}
              </span>
              <div style={{ color: '#aeaeae' }} className="d-none d-lg-inline" />
            </div>
          </MDBNavItem>
        </MDBNavbarNav>
        <MDBNavbarNav right style={specialCaseNavbarStyles} className="font-weight-bolder">
          <MDBNavItem>{renderLoginLogout()}</MDBNavItem>
        </MDBNavbarNav>
      </MDBNavbar>
    </>
  );
};

export default Header;

import { createSlice } from '@reduxjs/toolkit';

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    alerts: [],
  },
  reducers: {
    removeAlerts(state) {
      state.alerts.pop();
    },
    setAlerts(state, payload) {
      state.alerts = [...state.alerts, payload.payload];
    },
  },
});

export default alertsSlice.reducer;
export const { setAlerts, removeAlerts } = alertsSlice.actions;

import React, { useEffect } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import { useAppDispatch, useAppSelector } from 'config/Store';
import { CardItem } from 'common/RolesMenu';
import Preloader from 'common/Preloader';
import { closeAndCollapseSidebar, showAndOpenSideBar } from 'reducers/Sidebar.reducer';
import { NavLink, useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Alert } from '@material-ui/lab';
import { setArm } from 'reducers/Authentication.reducer';
import { ArmsArray } from 'pages/Arm/arms';

const ArmPage = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.authentication.account);
  const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(closeAndCollapseSidebar());
    }
  }, []);

  const devUserInfo = () =>
    process.env.NODE_ENV === 'development' ? (
      <>
        <Alert severity="warning" className="my-1">
          <strong>Пользователь: </strong> {account?.firstName}
        </Alert>
      </>
    ) : null;

  return isAuthenticated ? (
    <MDBContainer fluid className="my-4 scrollbar scrollbar-primary-table" style={{ overflowY: 'auto', height: 'calc(100vh - 100px)' }}>
      <MDBRow center>
        <MDBCol className="text-center">
          {devUserInfo()}
          <h2 style={{ fontWeight: 300, fontSize: '28px' }}>Выберите АРМ для продолжения работы</h2>
        </MDBCol>
      </MDBRow>
      <>
        <MDBContainer className="mt-4 px-4">
          <MDBRow className="mb-4 d-flex flex-wrap">
            {ArmsArray.length > 0 && account?.authorities ? (
              ArmsArray.filter((item) => item?.role.some((el) => account?.authorities?.includes(el))).map((key) => (
                <CardItem
                  name={key.name}
                  key={nanoid()}
                  description={key.description}
                  icon={key.icon}
                  visible={key.visible}
                  enabled={key.enabled}
                  isOldProgruz={key.isOldProgruz}
                  url={key.url}
                  onClick={() => {
                    if (key.isOldProgruz) {
                      window.open('/redirect', '_blank');
                    } else {
                      dispatch(setArm(key.code));
                      dispatch(showAndOpenSideBar());
                      history.push(`${key.code.toLowerCase()}`);
                    }
                  }}
                />
              ))
            ) : (
              <Preloader />
            )}
          </MDBRow>
        </MDBContainer>
      </>
    </MDBContainer>
  ) : (
    <MDBContainer fluid className="mt-2">
      <MDBRow center>
        <MDBCol className="text-center mt-4">
          <h2>
            Добро пожаловать в АС ПРОГРУЗ,
            <br />
            пожалуйста выполните вход
            <br />
          </h2>
          <br />
          <NavLink to="/login" className="mr-2">
            <MDBBtn className="danger-color-dark  white-text" style={{}}>
              Войти
            </MDBBtn>
          </NavLink>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ArmPage;

import { AUTHORITIES, FRONTEND_OLD_BASE_URL } from '../../config/Constants';

// eslint-disable-next-line
export const ArmsArray = [
  {
    role: [AUTHORITIES.OPERATOR_OLD, AUTHORITIES.ORG_PROGRUZ_Operator, AUTHORITIES.GVC_PROGRUZ_Operator, AUTHORITIES.MAIN_PROGRUZ_Operator],
    role_title: 'Оператор, 1-я очередь',
    name: 'АРМ «Оператор, 1-я очередь»',
    sname: '',
    visible: true,
    enabled: true,
    isOldProgruz: true,
    url: `${FRONTEND_OLD_BASE_URL}/`,
    icon: 'database',
    description: `Задачами АРМ являются:
    •	ведение НСИ;
    •	загрузка входных данных;
    •	моделирование погрузки по России в целом, по полигонам и по дорогам.
    `,
    code: 'OPERATOR-OLD',
  },
  {
    role: [AUTHORITIES.ANALYST_OLD, AUTHORITIES.ORG_PROGRUZ_Analyst, AUTHORITIES.GVC_PROGRUZ_Analyst, AUTHORITIES.MAIN_PROGRUZ_Analyst],
    role_title: 'АРМ «Аналитик, 1-я очередь»',
    name: 'АРМ «Аналитик, 1-я очередь»',
    sname: '',
    visible: true,
    enabled: true,
    isOldProgruz: true,
    url: `${FRONTEND_OLD_BASE_URL}/`,
    icon: 'chart-pie',
    description: `Задачами АРМ являются:
    •	моделирование погрузки моделирование погрузки по России в целом, по полигонам и по дорогам;
    •	формирование выходных форм.
    `,
    code: 'ANALYST-OLD',
  },
  {
    role: [AUTHORITIES.OPERATOR_OLD, AUTHORITIES.ORG_PROGRUZ_Operator, AUTHORITIES.GVC_PROGRUZ_Operator, AUTHORITIES.MAIN_PROGRUZ_Operator],
    role_title: 'АРМ «Оператор, очередь 2020»',
    name: 'АРМ «Оператор, очередь 2020»',
    sname: '',
    visible: true,
    enabled: true,
    isOldProgruz: false,
    url: '',
    icon: 'database',
    description: `Задачами АРМ являются:
    •	определение целевой погрузки по сети и по дорогам;
    •	загрузка истории перевозок;
    •	загрузка планов грузоотправителей
    •	моделирование перевозок по направлениям;
    •	формирование выходных форм.
    `,
    code: 'OPERATOR',
  },
  {
    role: [AUTHORITIES.ANALYST_OLD, AUTHORITIES.ORG_PROGRUZ_Analyst, AUTHORITIES.GVC_PROGRUZ_Analyst, AUTHORITIES.MAIN_PROGRUZ_Analyst],
    role_title: 'АРМ «Аналитик, очередь 2020»',
    name: 'АРМ «Аналитик, очередь 2020»',
    sname: '',
    visible: true,
    enabled: true,
    isOldProgruz: false,
    url: '',
    icon: 'chart-pie',
    description: `Задачами АРМ являются:
    •	моделирование перевозок по направлениям.
    •	формирование выходных форм.
    `,
    code: 'ANALYST',
  },
  {
    role: [
      // AUTHORITIES.ANALYST_OLD,
      // AUTHORITIES.ORG_PROGRUZ_Analyst,
      // AUTHORITIES.GVC_PROGRUZ_Analyst,
      // AUTHORITIES.MAIN_PROGRUZ_Analyst,
      AUTHORITIES.GVC_PROGRUZ_Admin,
      AUTHORITIES.GVC_PROGRUZ_Expert,
      AUTHORITIES.GVC_PROGRUZ_View,
    ],
    role_title: 'АРМ «Очередь 2022 - ЕИМ»',
    name: 'АРМ «Очередь 2022 - ЕИМ»',
    sname: '',
    visible: true,
    enabled: true,
    isOldProgruz: false,
    url: '',
    icon: 'chart-pie',
    description: `Задачами АРМ являются:
    •	формирование прогноза объемов перевозок грузов для взаимодействия с ЕИМ.
    •	корректировка сценариев прогноза в рамках работы ЕИМ.
    `,
    code: 'EIM',
  },
  {
    role: [
      // AUTHORITIES.ADMIN_OLD,
      // AUTHORITIES.MAIN_PROGRUZ_Admin,
      // AUTHORITIES.ORG_PROGRUZ_Admin,
      // AUTHORITIES.GVC_PROGRUZ_Admin,
      // AUTHORITIES.ANALYST_OLD,
      // AUTHORITIES.ORG_PROGRUZ_Analyst,
      // AUTHORITIES.GVC_PROGRUZ_Analyst,
      // AUTHORITIES.MAIN_PROGRUZ_Analyst,
      AUTHORITIES.GVC_PROGRUZ_Admin,
      AUTHORITIES.GVC_PROGRUZ_Expert,
      AUTHORITIES.GVC_PROGRUZ_View,
    ],
    role_title: 'АРМ «ПроГРУЗ.ТУ»',
    name: 'АРМ «ПроГРУЗ.ТУ»',
    sname: '',
    visible: true,
    enabled: true,
    isOldProgruz: false,
    url: '',
    icon: 'chart-pie',
    description: `Задачами АРМ являются:
    •	сбор данных для проектов примыкания;
    •	формирование долгосрочных прогнозов с учетом данных для проектов примыкания;
    •	анализ и учет дополнительных объемов перевозок грузов, заявляемых в рамках процесса рассмотрения заявок клиентов ОАО «РЖД».
    `,
    code: 'PROGRUZ',
  },
];

import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../assets/images/arrow-check.svg';

interface CheckboxProps {
  isCheckedProp?: boolean;
  isIndeterminate?: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: (value: boolean, event: Event) => void;
  className?: string;
}

export const Checkbox = memo((props: CheckboxProps) => {
  const { className, isCheckedProp = false, isIndeterminate = false, onChange, disabled = false, label = '' } = props;

  const [isChecked, setIsChecked] = useState<boolean>(isCheckedProp ?? false);

  const changeHandler = (event) => {
    if (!disabled) {
      onChange?.(!isChecked, event);
      setIsChecked((prev) => !prev);
    }
  };

  useEffect(() => {
    setIsChecked(isCheckedProp);
  }, [isCheckedProp]);

  return (
    <label
      className={classNames('checkbox', {
        [className as string]: !!className,
        checked: isChecked,
        indeterminate: isIndeterminate,
        disabled,
      })}
      onClick={changeHandler}
    >
      <div className="checkbox__indicator">
        <ArrowIcon className="checkbox__icon" />
      </div>
      {label && <span className="checkbox__label">{label}</span>}
    </label>
  );
});

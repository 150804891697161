import classNames from 'classnames';
import React, { forwardRef } from 'react';

export interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  inputClassName?: string;
  label?: JSX.Element | string;
  additionalText?: JSX.Element | string;
  trailingIcon?: JSX.Element | string;
  isError?: boolean;
  isSuccess?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, additionalText, trailingIcon, isError, inputClassName, className, ...props }, ref) => (
    <label className={classNames('input-root', className, { error: isError })}>
      {label && typeof label === 'string' ? <span className="label">{label}</span> : label}
      <div className="wrapper">
        <input className={classNames('input', inputClassName)} ref={ref} {...props} />
        {trailingIcon}
      </div>
      {additionalText && typeof additionalText === 'string' ? <span className="additionalText">{additionalText}</span> : additionalText}
    </label>
  )
);

const config = {
  VERSION: process.env.VERSION,
};

export default config;
// export const FRONTEND_OLD_URL: any = process.env.REACT_APP_OLD_FRONTEND_URL;
export const CONNECTION_STRING = `postgresql://progruz:jqsp25mz@${process.env.REACT_APP_DATABASE_URL}/progruz?currentSchema=progruz_schema`;
export const FRONTEND_NEW_BASE_URL = '/v2';
export const FRONTEND_OLD_BASE_URL = '/v1';

export const AUTHORITIES = {
  ADMIN_OLD: 'ROLE_PROGRUZ-ADMINS',
  OPERATOR_OLD: 'ROLE_PROGRUZ-OPERATORS',
  ANALYST_OLD: 'ROLE_PROGRUZ-ANALYSTS',
  OPERATOR: 'ROLE_PROGRUZ-OPERATORS',
  ANALYST: 'ROLE_PROGRUZ-ANALYSTS',
  // GVC_PROGRUZ_Admin: 'ROLE_GVC-PROGRUZ-ADMIN',
  // GVC_PROGRUZ_Analyst: 'ROLE_GVC-PROGRUZ-ANALYST',
  // GVC_PROGRUZ_Operator: 'ROLE_GVC-PROGRUZ-OPERATOR',
  GVC_PROGRUZ_Admin: 'USER_ADMIN',
  GVC_PROGRUZ_Analyst: 'USER_ANALYST',
  GVC_PROGRUZ_Operator: 'USER_OPERATOR',
  GVC_PROGRUZ_Expert: 'USER_EXPERT',
  GVC_PROGRUZ_View: 'VIEW',
  MAIN_PROGRUZ_Admin: 'ROLE_MAIN-PROGRUZ-ADMIN',
  MAIN_PROGRUZ_Analyst: 'ROLE_MAIN-PROGRUZ-ANALYST',
  MAIN_PROGRUZ_Operator: 'ROLE_MAIN-PROGRUZ-OPERATOR',
  ORG_PROGRUZ_Admin: 'ROLE_ORG-PROGRUZ-ADMIN',
  ORG_PROGRUZ_Analyst: 'ROLE_ORG-PROGRUZ-ANALYST',
  ORG_PROGRUZ_Operator: 'ROLE_ORG-PROGRUZ-OPERATOR',
};

export const ARMS = {
  ADMIN_OLD: 'ARM_ADMIN_OLD',
  OPERATOR_OLD: 'ARM_OPERATOR_OLD',
  ANALYST_OLD: 'ARM_ANALYST_OLD',
  OPERATOR: 'ARM_OPERATOR',
  ANALYST: 'ARM_ANALYST',
  EIM: 'ARM_EIM',
  PROGRUZ: 'ARM_PROGRUZ',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export const stepData = [
  {
    step: 1,
    title: 'Перспективные основные корреспонденции',
    axiosUrl: '/upload-main-flow',
  },
  {
    step: 2,
    title: 'Перспективные поучастковые объемы по “остальным” грузовым перевозкам',
    axiosUrl: '/upload-other-flow',
  },
  {
    step: 3,
    title: 'Перспективные объемы и средняя дальность “остальных” грузовых перевозок',
    axiosUrl: '/upload-avg-volumes',
  },
  {
    step: 4,
    title: 'Перспективные объемы “остальных” грузовых перевозок в разрезе дорог',
    axiosUrl: '/upload-other-transp',
  },
];

export const stepDataTwo = [
  {
    step: 1,
    title: 'Фактические основные корреспонденции',
    axiosUrl: '/upload-main-flow',
  },
  {
    step: 2,
    title: 'Перспективные поучастковые объемы по “остальным” грузовым перевозкам',
    axiosUrl: '/upload-other-flow',
  },
  {
    step: 3,
    title: 'Фактические объемы “остальных” грузовых перевозок в разрезе дорог',
    axiosUrl: '/upload-other-transp',
  },
];

export const sortTypes = [
  {
    type: 'name',
    name: 'По названию',
  },
  {
    type: 'create_ts',
    name: 'По дате создания',
  },
  {
    type: 'update_ts',
    name: 'По дате редакции',
  },
];

export const radioTypes = ['По возрастанию', 'По убыванию'];

export const dropOptions = [
  {
    name: '01 - № пп',
    options: ['171030', '81135'],
  },
  {
    name: '02 - Группа груза',
    options: ['Лесные грузы', 'Кокс каменноугольный'],
  },
  {
    name: '05 - Дорога отправления',
    options: ['ЗСБ'],
  },
];

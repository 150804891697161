import { createSlice } from '@reduxjs/toolkit';

const stepSlice = createSlice({
  name: 'step',
  initialState: {
    step: 1,
    sid: '',
    corrected: false,
  },
  reducers: {
    addStepValue(state, action) {
      state.step = action.payload;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
    setId(state, action) {
      state.sid = action.payload;
    },
    setCorrected(state, action) {
      state.corrected = action.payload;
    },
  },
});

export default stepSlice.reducer;
export const { addStepValue, setStep, setId, setCorrected } = stepSlice.actions;

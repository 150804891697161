import axios from 'axios';
import { getJwtToken } from 'util/Storage.util';

export const myApiPath =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL
    : // eslint-disable-next-line no-undef
      `${document.location.protocol}//${document.location.host}/progruz/api/v2`;

export const instance = axios.create({
  baseURL: myApiPath,
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getJwtToken()}`;
  return config;
});

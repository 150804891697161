import React from 'react';
import PageWithError from 'shared/error/PageWithError';
import { useTranslation } from 'react-i18next';

const PageNotAccessible = () => {
  const { t } = useTranslation();
  return <PageWithError errorNumber={t('error403.title')} errorMessage={t('error403.message')} />;
};

export default PageNotAccessible;

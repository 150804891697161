import React, { useState } from 'react';
import Logo from 'assets/RZD_logo_st_neg_rgb_2.svg';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useAppSelector } from 'config/Store';
import { Input } from '../common/Input';
import { ReactComponent as KeyIcon } from '../../assets/images/key.svg';
import { ReactComponent as PasswordStrokeEye } from '../../assets/images/password-stroke-eye.svg';
import { ReactComponent as ExclamationCircle } from '../../assets/images/exclamation_circle.svg';

interface ChangePasswordFormValues {
  newPassword: string;
  repeatPassword: string;
}

const defaultValues: ChangePasswordFormValues = {
  newPassword: '',
  repeatPassword: '',
};

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[()'"|;:+~`#?!@$%^&*=-]).{12,}$/;

export interface ChangePasswordFormProps {
  handleChangePassword: (password: string) => void;
}

export const ChangePasswordForm = ({ handleChangePassword }: ChangePasswordFormProps) => {
  const history = useHistory();
  const needChangePassword = useAppSelector((state) => state.authentication.needChangePassword);

  const form = useForm({ defaultValues, mode: 'onChange' });

  const [newPasswordInputType, setNewPasswordInputType] = useState('password');
  const [repeatPasswordInputType, setRepeatPasswordInputType] = useState('password');

  const handleShowNewPassword = () => {
    setNewPasswordInputType((prevState) => (prevState === 'password' ? 'text' : 'password'));
  };

  const handleShowRepeatPassword = () => {
    setRepeatPasswordInputType((prevState) => (prevState === 'password' ? 'text' : 'password'));
  };

  const validateNewPassword = (data: ChangePasswordFormValues['newPassword']) => {
    if (form.getValues('repeatPassword')) {
      form.trigger('repeatPassword');
    }

    return passwordRegex.test(data);
  };

  const validateRepeatPassword = (data: ChangePasswordFormValues['repeatPassword']) => data === form.getValues('newPassword');

  const onSubmit = (data: ChangePasswordFormValues) => {
    const { repeatPassword: newPassword } = data;
    handleChangePassword(newPassword);
  };

  const handleCancelChange = () => {
    history.push('/');
  };

  return (
    <form className="auth-form" onSubmit={form.handleSubmit(onSubmit)}>
      <div className="auth-form__header">
        <img src={Logo} alt="ОАО РЖД" style={{ width: '80px', marginBottom: '0px' }} />
        <div className="auth-form__title">АС ПРОГРУЗ</div>
      </div>
      <span className="auth-form__description">Изменение пароля</span>
      <div className="auth-form__body">
        <div className="auth-form__fields">
          <Controller
            control={form.control}
            name="newPassword"
            rules={{ validate: validateNewPassword }}
            render={(field, state) => (
              <Input
                label={
                  <span className={classNames('auth-form__field__label', { error: state.invalid })}>
                    Новый пароль <span className="required">*</span>
                  </span>
                }
                trailingIcon={
                  <div onClick={handleShowNewPassword}>
                    {newPasswordInputType === 'password' ? (
                      <KeyIcon className="auth-form__field__icon show-password" />
                    ) : (
                      <PasswordStrokeEye className="auth-form__field__icon show-password" />
                    )}
                  </div>
                }
                additionalText={
                  <div className={classNames('auth-form__field__additionalText', { error: state.invalid, hidden: !state.invalid })}>
                    <ExclamationCircle />
                    <span>
                      Пароль должен быть не короче 12 символов, без пробелов, содержать символы из четырех групп: буквы A-Z, a-z, цифры 0-9,
                      спецсимволы #!@$%^&*
                    </span>
                  </div>
                }
                required
                inputClassName="auth-form__field"
                placeholder="Введите пароль"
                type={newPasswordInputType}
                isError={state.invalid}
                {...field}
              />
            )}
          />
          <Controller
            control={form.control}
            name="repeatPassword"
            rules={{ validate: validateRepeatPassword }}
            render={(field, state) => (
              <Input
                label={
                  <span className={classNames('auth-form__field__label', { error: state.invalid })}>
                    Введите пароль ещё раз <span className="required">*</span>
                  </span>
                }
                trailingIcon={
                  <div onClick={handleShowRepeatPassword}>
                    {repeatPasswordInputType === 'password' ? (
                      <KeyIcon className="auth-form__field__icon show-password" />
                    ) : (
                      <PasswordStrokeEye className="auth-form__field__icon show-password" />
                    )}
                  </div>
                }
                additionalText={
                  <div className={classNames('auth-form__field__additionalText', { error: state.invalid })}>
                    <ExclamationCircle />
                    <span>
                      {state.invalid
                        ? 'Пароли должны совпадать'
                        : 'Пароль должен быть не короче 12 символов, без пробелов, содержать символы из четырех групп: буквы A-Z, a-z, цифры 0-9, спецсимволы #!@$%^&*'}
                    </span>
                  </div>
                }
                required
                inputClassName="auth-form__field"
                placeholder="Введите пароль"
                type={repeatPasswordInputType}
                isError={state.invalid}
                {...field}
              />
            )}
          />
        </div>
        <div className="auth-form__actions">
          <button type="submit" className="auth-form__submit button" disabled={!form.formState.isValid}>
            Изменить пароль
          </button>
          <button type="button" className="auth-form__submit button" onClick={handleCancelChange}>
            Продолжить без изменения
          </button>
        </div>
      </div>
    </form>
  );
};

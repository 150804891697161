import React from 'react';
import { MDBContainer } from 'mdbreact';

interface IErrorBoundaryProps {
  readonly children: JSX.Element | JSX.Element[];
}

interface IErrorBoundaryState {
  readonly error: any;
  readonly errorInfo: any;
  hasError: any;
  chunkError: any;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  // readonly state: IErrorBoundaryState = { error: undefined, errorInfo: undefined};
  // eslint-disable-next-line react/state-in-constructor
  state: IErrorBoundaryState = { error: undefined, errorInfo: undefined, hasError: false, chunkError: undefined };

  // --------Перехватывает ошибку, возникающую при загрузке chunk в случае закешированного в index.html несуществующего chunk*.js ----------
  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      chunkError: /Loading( CSS)? chunk [\d]+ failed/.test('Loading chunk 8 failed'),
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    // const { error, errorInfo} = this.state;
    const { error, errorInfo, hasError, chunkError } = this.state;
    if (chunkError && hasError) {
      console.log(chunkError);
      // window.location.reload();
    } else if (errorInfo) {
      const errorDetails =
        process.env.NODE_ENV === 'development' ? (
          <details className="preserve-space">
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        ) : undefined;
      return (
        <MDBContainer className="mt-1">
          <h2 className="error">Возникла ошибка при работе приложения</h2>
          {errorDetails}
        </MDBContainer>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'material-design-icons/iconfont/material-icons.css';
import { Provider } from 'react-redux';
import ErrorBoundary from 'shared/error/ErrorBoundary';
import getStore from 'config/Store';
import App from 'App';
import { bindActionCreators } from 'redux';
import setupAxiosInterceptors from 'config/AxiosInterceptor';
import { clearAuthentication } from 'reducers/Authentication.reducer';

// fix error proccess not defined
// @ts-ignore
window.process = {};

const store = getStore();
const rootElement = document.getElementById('root');

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  rootElement
);
